import { Container, Grid } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const links="https://wallet.palmsmartchain.io/sign-up?referralCode=palmsmartchain";
const Referral = () => {
    return (
        <div className='relative py-24'>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item lg="12">
                        <div className="cta-wrap3 lg:flex md:flex items-center justify-between">
                            <div className="lg:px-24 py-14">
                                <div className="section-title mb-10">
                                    <h2 className="font-bold text-zinc-900 lg:text-6xl md:text-6xl sm:text-4xl text-4xl mb-4">Refer a Friend & Get 10% PALM</h2>
                                </div>
                                <Link to={links} target="_blank" className="buttons StartBuilding capitalize">
                                    Refer a Friend
                                </Link>
                            </div>
                            <div className="cta-3-thumb">
                                <img src={require('../../../assets/cta_3-1.png')} alt="img" className='animate-pulse ' />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Referral