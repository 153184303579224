import { Container, Grid } from '@mui/material'
import React from 'react'
import logo from "../../../assets/logo/palm-smart-chain-logo.png";
import { HashLink as Link } from "react-router-hash-link";
const Palmstaking = () => {
    const links="https://wallet.palmsmartchain.io/sign-up?referralCode=palmsmartchain";
    return (
        <div className='relative z-0 py-32 overflow-hidden'>
            <div className='absolute top-0 z-0 m-auto left-0 right-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full m-auto' alt='' />
            </div>
            <Container maxWidth="xl" className="relative">
                <div className='text-center mb-14'>
                    <div className="text-2xl font-normal text-white mb-5">Unlock the Limitation</div>
                    <div className="text-6xl font-bold gradient-text">Buy, Stake & Earn more PALM</div>
                    <div className='py-5 text-white text-xl text-normal'>
                        We are offer <span className='text-orange-600 font-medium text-2xl'>3</span> <span className='text-orange-600 font-medium text-2xl'>staking</span> plans.
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                        <Link to={links}>
                        <div className='team-list' style={{ borderRadius: '15px' }}>
                            <div className='team-profile p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={logo} className='w-16 h-16 m-auto object-contain' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        6 Months (180 Days)
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                    APR upto 30%
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                    <Link to={links}>
                        <div className='team-list' style={{ borderRadius: '15px' }}>
                            <div className='team-profile p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={logo} className='w-16 h-16 m-auto object-contain' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        8 Months (240 Days)
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                APR upto 40%
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Grid>
                    <Grid item lg="4" md="4" sm="6" xs="12">
                    <Link to={links}>
                        <div className='team-list' style={{ borderRadius: '15px' }}>
                            <div className='team-profile p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={logo} className='w-16 h-16 m-auto object-contain' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        11 Months (330 Days)
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                APR upto 60%
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Grid>
                    
                </Grid>
            </Container>
        </div>
    )
}

export default Palmstaking