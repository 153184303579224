import React from 'react'
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom'; 
import LineChart from '../chart/LineChart';



const PalmsAnalysis = () => {
    return (
        <div className='py-32 bg-zinc-950 relative isolate overflow-hidden'>
            <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                <div
                    style={{
                        clipPath:
                            "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7143fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                />
            </div>
            <Container maxWidth="lg">
                <div className="text-center mb-10">
                    <div className="text-6xl font-bold gradient-text">Palm Analysis</div>
                </div>

                <Grid container spacing={2}>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>1K</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Total Listed Users
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>500</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Today New Users
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>200</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Total Palm Sell
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>10,000</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Remaining Palm
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="12" md="12" sm="12" xs="12">
                        <div className='bg-purple-950 bg-opacity-20 py-4 rounded-3xl'>
                             <LineChart/>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{marginTop:'1rem'}}>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>1K</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Total Staking
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>500</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        Today New Stake
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50 px-5'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>200</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                    Total Withdrawal Rewards
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="3" md="3" sm="6" xs="12">
                        <div className='Join_Card border-2 border-purple-700 bg-opacity-50'>
                            <div className='text-center m-auto'> 
                                <div className='titlehover mb-3'>
                                    <h4 className='text-white text-2xl text-center'>10,000</h4>
                                </div>
                                <div>
                                    <h4 className='text-center text-white text-xl'>
                                        TVL (Total Value Locked)
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg="12" md="12" sm="12" xs="12">
                        <div className='bg-purple-950 bg-opacity-20 py-4 rounded-3xl'>
                             <LineChart/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default PalmsAnalysis