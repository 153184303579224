import { Container, Grid } from "@mui/material";
import React from "react";
import pinlogo from "../../../assets/intro_1-2.png";
import pinlogo1 from "../../../assets/intro_1-1.png";
import pinlogo2 from "../../../assets/cta_3-1.png";
import pinlogo3 from "../../../assets/feature_1-1.png";
import pinlogo4 from "../../../assets/favicon.png";
import ecoenergy from "../../../assets/eco-energy-3d-render-icon.webp";
import banks from "../../../assets/bank_card-min.png";
import wallet from "../../../assets/wallet-icons/wallet.svg";

const PSCProducts = () => {
    return (
        <div className="py-20 PSCProducts relative z-10">
            <Container maxWidth="xl">
                <div className="text-center mb-14">
                    <div className="text-2xl font-normal text-white mb-5">PSC Products</div>
                    <div className="lg:text-6xl md:text-5xl sm:text-4xl text-5xl font-bold gradient-text">
                        The PSC offers
                        <br /> Diverse Decentralized Solutions
                    </div>
                </div>

                <div className="main_feature flex flex-col xl:flex-row lg:flex-row gap-1 relative">
                    <div className="w-full lg:w-2/3 md:w-2/2 flex flex-col gap-1">
                        <div className="location-box flex bg-location items-start p-4 md:py-6 md:px-10 relative rounded-3xl lg:h-80 md:h-[380px] sm:h-[380px] h-[390px] w-full flex-wrap xl:flex-nowrap">
                            <div className="lg:w-1/2">
                                <h4 className="text-white text-2xl font-semibold mb-2">Palm Smart Chain</h4>
                                <p className="text-footertext text-sm lg:text-base">Palm Smart Chain is a powerful EVM-compatible blockchain, offering secure, scalable, and decentralized infrastructure for building dApps and smart contracts. It supports seamless integration with existing
                                Ethereum tools and networks, providing businesses with robust solutions for deploying innovative blockchain applications with low fees and high performance.</p>
                            </div>
                            <div className="inner__img1 bg-cover xl:h-full bg-no-repeat lg:w-1/2 w-full rounded-3xl relative">
                                <img src={pinlogo} alt="" className="absolute top-2 left-40 w-12 h-12" />
                                <img src={pinlogo1} alt="" className="absolute top-5 right-8 w-12 h-12" />
                                <img src={pinlogo2} alt="" className="absolute top-1/3 left-0 sm:right-1/3 w-20 h-20" />
                                <img src={pinlogo3} alt="" className="absolute bottom-7 left-20 w-12 h-12" />
                                <img src={pinlogo4} alt="" className="absolute bottom-5 right-10 w-12 h-12" />
                            </div>
                        </div>
                        <div className="flex gap-1 flex-col md:flex-row">
                            <div className="w-full lg:h-full md:h-[464px] sm:h-[464px] h-[464px] text-white rounded-3xl p-4 md:py-5 md:px-10 bg-cryptoBg">
                                <div>
                                    <h4 className="text-white text-2xl font-semibold mb-2">Palm Wallet</h4>
                                    <p className="text-footertext text-sm lg:text-base">Palm Wallet is a secure and user-friendly crypto wallet designed for the Palm Smart Chain
                                    ecosystem. It supports seamless storage, sending, and receiving of digital assets, offering users a
                                    safe and convenient way to manage their cryptocurrencies and interact with dApps within the Palm
                                    Smart Chain network.
                                    </p>
                                </div>
                                <div className="below-img xl:mt-[7rem] lg:mt-[5.5rem]">
                                     {/*<div className="flex justify-end relative before:absolute before:max-w-20 before:rotate-45 before:w-full before:bg-cover before:bg-no-repeat before:top-5 before:left-8 before:h-20 coin_mast">
                                        <img src={require('../../../assets/bitcoin.png')} alt="" className="max-w-32" />
                                    </div>*/}
                                    <div className="flex justify-center">
                                        <img src={require('../../../assets/wallets.webp')} alt="" className="lg:w-[20rem] md:w-[14rem]" />
                                    </div>
                                    {/*<div className="flex justify-end relative before:absolute before:max-w-52 before:rotate-45 before:w-full before:bg-cover before:bg-no-repeat before:top-5 before:left-12 before:min-h-52 bot_coin_mast">
                                        <img src={require('../../../assets/usdt.png')} alt="" className="max-w-32" />
                                    </div>*/}
                                </div>
                            </div>
                            <div className="w-full bg-cardBgStaking lg:h-[341px] md:h-[464px] sm:h-[464px] h-[464px] text-white rounded-3xl p-4 md:py-6 lg:px-10">
                                <div className="wallet flex gap-2 items-center text-xs uppercase font-semibold justify-center mb-3 relative">
                                    <img src={wallet} alt="" className="w-4 h-4" />Aman
                                </div>
                                <div className="relative">
                                    <div className="mb-4 text-center">
                                        <h2 className="text-5xl xl:text-8xl font-bold">Staking</h2> 
                                    </div>
                                    <p className="text-footertext text-sm">Aman Staking provides a versatile staking platform, supporting multiple blockchain networks. It
                                        enables users to stake their tokens securely and earn rewards, helping strengthen blockchain
                                        ecosystems. With a focus on accessibility and security, Aman Staking enhances the staking
                                        experience across various decentralized networks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 flex flex-col z-999">
                        <div className="card-box bg-cryptoBg p-4 md:py-6 md:px-10 rounded-3xl flex flex-col gap-20 xl:h-[668px] lg:h-[666px] justify-between">
                            <div className="relative z-10">
                                <h4 className="text-white text-2xl font-semibold mb-2">Palm Pay</h4>
                                
                                <p className="text-sm lg:text-base">Palm Pay is an upcoming crypto payment, designed to make digital payments simple and accessible.
                                It will enable users to pay for goods and services using cryptocurrencies, combining the convenience
                                of traditional payment Apps with the security and flexibility of blockchain technology.</p>
                            </div>
                            <div className="img relative">
                                <div className="absolute -z-0 -top-[11rem] md:-top-56 md:left-0 xl:-top-28 xl:left-20 lg:block md:hidden">
                                    <img src={require('../../../assets/slice.png')} alt="" className="max-w-72" />
                                </div>
                                <div>
                                    <img src={banks} alt="" className="md:w-[100%]" />
                                </div>
                            </div>
                        </div>
                        <div className="investment_bg bg-location mt-1 md:mt-0 lg:absolute md:relative sm:relative relative text-white rounded-3xl py-5 px-6 md:-right-1.5 lg:right-0 bottom-0 lg:h-[305px] md:h-[380px] sm:h-[380px] h-[187px] md:w-65P lg:w-2/3 bg-cover bg-no-repeat flex items-end">
                            <div className="xl:max-w-96">
                                <h4 className="text-white text-2xl font-semibold mb-2">Ruka Swap</h4>
                                <p className="text-footertext text-sm lg:text-base">Instantly exchange cryptocurrencies with ease on our intuitive platform, ensuring seamless transactions and optimal market rates. </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_feature flex flex-col lg:flex-row gap-1 relative mt-2">
                    <div className="w-full lg:w-2/3 flex flex-col gap-1">
                        <div className="location-box flex bg-location items-start p-4 md:py-6 md:px-10 relative rounded-3xl lg:h-80 md:h-[477px] sm:h-[477px] h-[495px] w-full flex-wrap xl:flex-nowrap">
                            <div className="lg:w-1/2">
                                <h4 className="text-white text-5xl font-semibold mb-2">Eco</h4>
                                <p className="text-footertext text-sm lg:text-base">Eco is an innovative social media platform that combines the features of X (Twitter) and Telegram,
                                offering users a dynamic space for communication, content sharing, and networking. Built on
                                blockchain technology, Eco prioritizes privacy, security, and community engagement, making it a
                                decentralized alternative to traditional social media platforms.</p>
                            </div>
                            <div className="inner__img bg-cover xl:h-full bg-no-repeat lg:w-1/2 w-full rounded-3xl relative">
                                <img src={ecoenergy} alt="" className="w-60 m-auto" />
                            </div>
                        </div>
                        <div className="flex gap-1 flex-col md:flex-row">
                            <div className="relative w-full h-full text-white rounded-3xl p-4 md:py-5 md:px-10 bg-cryptoBg">
                                <div className="relative z-10 mb-5">
                                    <h4 className="text-5xl xl:text-4xl text-white font-bold mb-2">Challenge Me</h4>
                                    <p className="text-footertext ">ChallengeMe is a unique social platform that brings together the fun of TikTok with engaging
                                    challenges. Users can create, share, and participate in video challenges, competing for rewards and
                                    recognition. ChallengeMe fosters creativity and community while providing a fresh, interactive
                                    experience for social media enthusiasts.
                                    </p>
                                </div>
                                <div className="lg:absolute md:absolute -bottom-10 right-0  -z-0">
                                    <img src={require('../../../assets/desktop.png')} alt="" className="w-56 m-auto" />
                                </div>
                            </div>
                            <div className="w-full bg-cardBg md:h-360 text-white rounded-3xl p-4 md:py-6 lg:px-10">
                                <div className="wallet flex gap-2 relative items-center text-xl uppercase font-semibold justify-center mb-5">
                                    Palm
                                </div>
                                <div className="relative">
                                    <div className="mb-4 text-center">
                                        <h2 className="text-5xl xl:text-9xl font-bold text-center">NFTs</h2> 
                                    </div>
                                    <p className="text-center text-sm">Palm NFTs is a dedicated NFT marketplace on the Palm Smart Chain, empowering creators to mint, buy, and sell unique digital assets. This secure and user-friendly platform facilitates seamless trading and ownership of NFTs, opening up new opportunities for artists, collectors, and businesses in the digital economy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/3 flex flex-col z-999">
                        <div className="card-box bg-cryptoBg p-4 md:py-6 md:px-10 rounded-3xl flex flex-col gap-20 h-full justify-between">
                            <div>
                                <h4 className="text-5xl xl:text-9xl text-white font-bold text-center">Rema</h4>
                                <p className="text-footertext text-sm lg:text-base">Rema is an AI-powered chatbot designed for Arabic speakers, offering conversational AI services
                                similar to ChatGPT. Equipped with voice interaction capabilities, Rema provides an accessible and
                                intuitive platform for users to communicate, ask questions, and receive information in Arabic,
                                making AI technology more inclusive and localized. 
                                </p>
                            </div>
                            <div className="img relative -top-11">
                                <div className="">
                                    <img src={require('../../../assets/ai-on-laptop.webp')} alt="" className="w-60 m-auto" />
                                </div>
                                 
                            </div>
                        </div> 
                    </div>
                </div>
 
            </Container>
        </div>
    );
};

export default PSCProducts;
