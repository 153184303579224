import React from 'react'
import { PieChart } from '@mui/x-charts/PieChart';
import { Container, Grid } from '@mui/material';

const data = [
  { id: 0, value: 70, label: 'staking 70%' },
  { id: 1, value: 10, label: 'challenge Me 10%' },
  { id: 2, value: 10, label: 'ICO 10%' },
  { id: 3, value: 5, label: 'Admin & Developer 5%' },
  { id: 4, value: 5, label: 'Marketing 5%' },
];


const PalmTokonomics = () => {
  return (
    <div className='pb-20 bg-zinc-950 relative isolate'>
      <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#7143fa] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>
      <Container maxWidth="lg" className="relative" sx={{overflow:'hidden'}}>
        <div className="text-center mb-10">
          <div className="text-6xl font-bold gradient-text">Palm Distribution </div>
        </div>

        <Grid container spacing={2}>
          <Grid item lg="12" md="12" sm="12" xs="12">
            <div className='LineCharts border-2 lg:flex md:flex items-center justify-around border-slate-800 lg:p-12 md:p-10 sm:p-4 p-3 rounded-3xl'>
               
                <PieChart
                  series={[
                    {
                      data,
                      highlightScope: { fade: 'global', highlight: 'item' },
                      faded: { innerRadius: 30, additionalRadius: -30, color: '#7143fa' },
                      labels: {
                        visible: true,
                        color: '#fff',
                        fontSize: 14,
                        display: 'none'
                      },

                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  height={350}
                  width={400}
                />
 
              <div className='p-2'>
                  <p className='text-zinc-900 text-xl mb-3 font-medium capitalize flex items-center gap-2'><span className='w-[20px] h-[20px] bg-[#02b2af]' /> staking 70%</p>
                  <p className='text-zinc-900 text-xl mb-3 font-medium capitalize flex items-center gap-2'><span className='w-[20px] h-[20px] bg-[#2e96ff]' /> challenge Me 10%</p>
                  <p className='text-zinc-900 text-xl mb-3 font-medium capitalize flex items-center gap-2'><span className='w-[20px] h-[20px] bg-[#b800d8]' /> ICO 10%</p>
                  <p className='text-zinc-900 text-xl mb-3 font-medium capitalize flex items-center gap-2'><span className='w-[20px] h-[20px] bg-[#60009b]' /> Admin & Developer 5%</p>
                  <p className='text-zinc-900 text-xl mb-3 font-medium capitalize flex items-center gap-2'><span className='w-[20px] h-[20px] bg-[#2731c8]' /> Marketing 5%</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default PalmTokonomics