import { Container, Grid } from '@mui/material'
import React from 'react'
import Decentralized from '../../../assets/wallet-icons/logo_twitter.png'
import metamask from '../../../assets/wallet-icons/wallet-img-3.webp'
import Rainbow from '../../../assets/wallet-icons/wallet-img-4.webp'
import TrustWallet from '../../../assets/wallet-icons/wallet-connect.png'

const EVMBlockchainSupportedWallets = () => {
    return (
        <div className='relative z-0 py-32'>
            <div className='absolute top-0 z-0 m-auto left-0 right-0'>
                <img src={require('../../../assets/bg-line.png')} className='object-contain opacity-50 w-full h-full m-auto' alt='' />
            </div>
            <Container maxWidth="xl" className="relative">
                <div className='text-center mb-14'>
                    <div className="text-2xl font-normal text-white mb-5">Unlock the future with</div>
                    <div className="text-6xl font-bold gradient-text">EVM Blockchain Supported Wallets</div>
                    <div className='py-5 text-white text-xl text-normal'>
                        Secure, versatile, and trusted by millions. From <span className='text-orange-600 font-medium text-2xl'>MetaMask</span> to <span className='text-orange-600 font-medium text-2xl'>Trust Wallet</span> and <span className='text-orange-600 font-medium text-2xl'>Coinbase Wallet</span>, <br />
                        manage your digital assets seamlessly across leading platforms and over <span className='text-orange-600 font-medium text-2xl'>430 wallets</span>.
                    </div>
                </div>
                <Grid container spacing={2}>
                    <Grid item xl="3" lg="3" md="4" sm="6" xs="12">
                        <div className='team-list h-full' style={{ borderRadius: '15px' }}>
                            <div className='team-profile h-full p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={Decentralized} className='w-16 h-16 m-auto rounded-full' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        Rabby wallet
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                    All-in-one cryptocurrency wallet offering decentralized App (dApp) support. Enjoy web3 journey.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl="3" lg="3" md="4" sm="6" xs="12">
                        <div className='team-list h-full' style={{ borderRadius: '15px' }}>
                            <div className='team-profile h-full p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={metamask} className='w-16 h-16 m-auto' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        MetaMask Wallet
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                    User-friendly Ethereum wallet for seamless interaction with dApps and decentralized finance (DeFi) platforms.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl="3" lg="3" md="4" sm="6" xs="12">
                        <div className='team-list h-full' style={{ borderRadius: '15px' }}>
                            <div className='team-profile h-full p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={TrustWallet} className='w-16 h-16 m-auto' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        Wallet Connect
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                    Protocol facilitating secure communication between mobile wallets and decentralized applications (dApps) for seamless interaction.
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xl="3" lg="3" md="4" sm="6" xs="12">
                        <div className='team-list h-full' style={{ borderRadius: '15px' }}>
                            <div className='team-profile h-full p-5' style={{ borderRadius: '15px' }}>
                                <div className='mb-2 text-center'>
                                    <div className='iconsImage text-center'>
                                        <img src={Rainbow} className='w-16 h-16 m-auto' />
                                    </div>
                                    <div className='contentcards text-2xl text-white py-2'>
                                        Rainbow Wallet
                                    </div>
                                </div>
                                <div className='text-slate-300 text-sm text-center'>
                                    Secure multi-currency wallet supporting various dApps, cryptocurrencies, and decentralized finance (DeFi) protocols.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default EVMBlockchainSupportedWallets