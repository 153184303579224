import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Home from './components/pages/main/Home';
import Footer from './components/footer/Footer';


const App = () => {
  return (
    <BrowserRouter basename='/'>
      <Header />
      <Routes>
         <Route path="/" element={<Home />} /> 
      </Routes>
       <Footer />
    </BrowserRouter>
  )
}

export default App